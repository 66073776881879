<template>
  <div class="all">
    <div class="header">
      <van-nav-bar
              left-arrow
              @click-left="onClickLeft()"
      >
        <template #right>
          <van-dropdown-menu v-if=" country == 'th'">
            <van-dropdown-item v-model="language" :options="languageOption" @change="changeLanguage()"/>
          </van-dropdown-menu>
        </template>
      </van-nav-bar>

      <!-- TODO: Change header according to hostname -->
      <div class="header-img-backgroud" v-if = " this.brandName =='esteelauder'">
        <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"
                :src=el_imageHeader
        />
      </div>
      <div class="header-img-backgroud" v-else-if = " this.brandName =='bobbibrown'">
        <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"
                :src=bb_imageHeader
        />
      </div>
      <div class="header-img-backgroud-green" v-else-if = " this.brandName =='lamer'">
        <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"
                :src=lm_imageHeader
        />
      </div>
      <div class="header-img-backgroud" v-else-if = " this.brandName =='origins'">
        <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"
                :src=or_imageHeader
        />
      </div>
      <div class="header-img-backgroud" v-else-if = " this.brandName =='labseries'">
        <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"
                :src=ls_imageHeader
        />
      </div>
      <div class="header-img-backgroud" v-else>
        <van-image 
              class="head-img"
              referrerpolicy="no-referrer"
              fit="cover"
              :src=el_imageHeader
        />
        
      </div>
      <!-- <div>
         <van-image
                class="head-img"
                referrerpolicy="no-referrer"
                fit="cover"

                :src="require('/public/img/EL logo_white.png')"
        />
      </div>
      -->
    </div>
    <div class="main">
      <router-view></router-view>
    </div>

    <!-- TODO: Change footer according to hostnanme -->
    <div class="footer">  
      <van-image v-if=" this.brandName =='esteelauder'"
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=el_imageFooter
              
      />

      <van-image v-else-if=" this.brandName =='bobbibrown'"
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=bb_imageFooter
              
      />

      <van-image v-else-if=" this.brandName =='lamer'"
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=lm_imageFooter
              
      />

      <van-image v-else-if=" this.brandName =='origins'"
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=or_imageFooter
              
      />

      <van-image v-else-if=" this.brandName =='labseries'"
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=ls_imageFooter
              
      />

      <van-image v-else
              class="footer-img"
              align="middle"
              referrerpolicy="no-referrer"
              :src=el_imageFooter
              
      />


      <p class="footer-font">
        <!-- Copyright &#169;  -->
        {{ $t("index.copyRight") }}
         <!-- 2022 -->
      </p>
    </div>

  </div>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Toast,
  NavBar
} from 'vant';
import {Image as VanImage} from 'vant';

export default {
  components: {
    [VanImage.name]: VanImage,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      value: 0,
      language: 'en',
      country: '',
      el_imageHeader: require('/public/img/esteelauder_logo_header.png'),
      el_imageFooter: require('/public/img/esteelauder_logo_footer.png'),
      bb_imageHeader: require('/public/img/bobbibrown_logo_header.png'),
      bb_imageFooter: require('/public/img/bobbibrown_logo_footer.png'),
      lm_imageHeader: require('/public/img/lamer_logo_header.png'),
      lm_imageFooter: require('/public/img/lamer_logo_footer.png'),
      or_imageHeader: require('/public/img/or_logo_header.png'),
      or_imageFooter: require('/public/img/or_logo_footer.png'),
      ls_imageHeader: require('/public/img/ls_logo_header.png'),
      ls_imageFooter: require('/public/img/ls_logo_footer.png'),
      languageOption: [
        { text: 'ภาษาไทย', value: 'thai' },
        { text: 'English', value: 'en' }
        ],
      brandName:''
    };
  },
  mounted() {
    // this.language = this.$route.params.lang
    this.country = this.$route.params.countryPath
    this.getCountryAndBrand()
    if(this.brandName=='delamer')this.brandName = 'lamer'
  },
  methods: {
    onClickLeft() {
      this.$router.push({name: "EnterNumber", params: {lang : this.$root.$i18n.locale}})
    },
    changeLanguage() {
      this.$root.$i18n.locale = this.language
    },

    // get brand name and country by request url.
    getCountryAndBrand(){
      let countryList = ['sg','th','my']
      let brandList = ['esteelauder', 'bobbibrown', 'lamer', 'labseries', 'origins']
      let country = location.host.split('.').pop()
      ///TODO: Change the number to get the correct brand
      let brand = location.host.split('.')[1]
      // localStorage.setItem('countryPath','sg')
      localStorage.setItem('brand','esteelauder')
      if(countryList.includes(country)){
        localStorage.setItem('countryPath',country)
      }
      if (brandList.includes(brand)) {
        this.brandName = brand
        localStorage.setItem('brand', brand)
      } else {
        this.brandName = 'origins'
      }
      if(brand == 'delamer'){
        localStorage.setItem('brand','lamer')
      }
    },
    /**
     * Change brand image according to hostname
     */
    // checkBrand(){
    //   console.log(this.imageFooter)
    //   var brandName = location.hostname.split('.')[1]
    //   var imageHeaderPath = '/public/img/' + brandName +'_logo_header.png'
    //   var imageFooterPath = '/public/img/' + brandName +'_logo_footer.png'
    //   this.imageHeader = require(imageHeaderPath)
    //   this.imageFooter = require(imageFooterPath)
    //   console.log(this.imageFooter)
    // },
  }
};
</script>

<style lang="less">
  .SecondTitle {
    overflow-wrap: break-word;
    color: black;
    font-size: 20px;
    white-space: nowrap;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 20px
  }
  .tips {
    display: block;
    overflow-wrap: break-word;
    color: #323233;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
    font-family: Times New Roman;
  }
  .SecondTitleRegistration {
    overflow-wrap: break-word;
    color: black;
    font-size: 20px;
    white-space: nowrap;
    font-family: Helvetica-Regular;
    font-weight: bold;
    margin-top: 20px;
    font-style:italic;
  }
  .tipsRegistration {
    display: block;
    overflow-wrap: break-word;
    color: #323233;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
    font-family: Helvetica-Regular;
    font-style:italic;
    font-weight: bold;
  }
  .small-tips {
    display: block;
    overflow-wrap: break-word;
    color: rgba(160, 160, 160, 1);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px
  }

  .header-img-backgroud {
    height: 3em;
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    text-align:center;
    
  }

  .header-img-backgroud-green {
    height: 3em;
    background-color: #026241;
    width: 100%;
    text-align:center;
  }

  .head-img {
    width: 100;
    height: 100;
    // transform: (translate(3%,-25%));
    // margin: -3%;
    // float: right;
  }

  .footer-img {
    height: 100%;
    margin: 15px 30%;
    margin-bottom: 10px;
    display: flex;
  }
  .footer-font {
    display: block;
    overflow-wrap: break-word;
    color: rgba(198, 198, 198, 1);
    font-size: 12px;
    font-family: Helvetica;
    white-space: nowrap;
    line-height: 14px;
    text-align: center;
  }
  .all {
    background-color: white;
  }
  .main{
    margin: 0 5%;
    min-height: calc(70vh);
  }
  .header{
    /*height: 106px;*/
    line-height: 80px;
  }
  .footer{
    background-color: white;
   }

  .van-dropdown-menu__title::after{
    border-color: transparent transparent #000000 #000000
  }

  .van-dropdown-menu__bar {
    margin-bottom: 2px;
    height: unset;
    box-shadow: none !important;
  }

  input.agreeCheckBox {
        transform : scale(1.5);
        margin-top: 33%
        
    }
    .agreeStatement {
        margin-left:10px;
    }
    .second-tips{
        display: inline-flex;
        font-size: 14px;
        font-family: Helvetica;
        font-weight: 400;
        margin-top: 20px;
        line-height: 20px;
    }
</style>
